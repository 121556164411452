// Third party
import React from "react";
import * as PropTypes from "prop-types";
import { graphql } from "gatsby";

// Components
import Layout from "../components/Layout";
import Container from "../components/Container";
import Section from "../components/Section";
import Breadcrumb from "../components/Breadcrumb";
import Author from "../components/Author";
import SEO from "../components/seo";

class PageTemplate extends React.Component {
  render() {
    const page = this.props.data.contentfulPage;
    const {
      title,
      slug,
      body: {
        childMarkdownRemark: { html, excerpt },
      },
      author,
    } = page;

    const breadcrumbs = [
      {
        id: 1,
        title: "Home",
        slug: "/",
      },
      {
        id: 2,
        title: title,
        slug: slug,
      },
    ];
    return (
      <Layout>
        <SEO
          title={`${title}`}
          description={`${excerpt}`}
          url={this.props.location.href}
        />
        <Breadcrumb items={breadcrumbs} />
        <Section>
          <Container>
            <div className="grid">
              <div className="grid__col grid__col--3-of-5 grid__col--centered">
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: html }} />
                <Author {...author} />
              </div>
            </div>
          </Container>
        </Section>
      </Layout>
    );
  }
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageTemplate;

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      body {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      author {
        title
        avatar {
          fixed(width: 126, height: 126) {
            src
          }
        }
      }
    }
  }
`;
